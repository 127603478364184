<script>
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: ['token', 'user'],
  data() {
    return {
      confirm_password: '',
      linkCheckResult: undefined,
      loading: true,
      password: '',
      result: undefined,
      submitting: false,
    };
  },
  methods: {
    ...mapActions('auth', ['userLoggedIn']),
    async checkLoggedIn() {
      if (await this.$auth.currentAuthenticatedUser().catch(() => undefined)) {
        await this.$router.push({ path: '/dashboard' }).catch(() => undefined);
      }
    },
    async submit() {
      const target = `${process.env.VUE_APP_API_ENDPOINT}/public/users/reset-password`;
      const data = {
        cognito_user_id: this.user,
        password: this.password,
        subscriber: String(this.$i18n.t('site.subscriberId')),
        token: this.token,
      };
      this.submitting = true;
      this.result = await axios.post(target, data).catch(({ response = undefined }) => response);
      this.submitting = false;
      if (this.result.status === 200) {
        await this.$auth.signIn(this.result.data.username, this.password).catch((e) => console.log(e));
        await this.userLoggedIn(this.$auth);
        setTimeout(() => this.$router.push({ path: '/dashboard' }), 3000);
      }
    },
  },
  async mounted() {
    await this.checkLoggedIn();
    const target = `${process.env.VUE_APP_API_ENDPOINT}/public/users/verify-password-reset-token`;
    this.linkCheckResult = await axios
      .get(target, {
        params: { token: this.token, cognito_user_id: this.user },
      })
      .catch(({ response = undefined }) => response);
    this.loading = false;
  },
};
</script>

<template>
  <div class="container">
    <div class="row p-2">
      <div class="col-12 border-bottom text-center">
        <h2 class="title">Update Password</h2>
      </div>
    </div>
    <div v-if="loading" class="col-12 py-3">
      Please wait while we check your link. <i class="icon-spinner11 ml-2 spinner small"></i>
    </div>
    <div v-else-if="linkCheckResult && linkCheckResult.status !== 204">
      <div>
        <div class="alert alert-info text-center">
          <p>The link you have provided is no longer valid.</p>
          <p><i class="icon-warning2 border-3 rounded-round p-2 text-info"></i></p>
          <p>If you request multiple password links, only the latest one will work.</p>
        </div>
        <div class="text-center">
          <p>
            <a
              v-if="linkCheckResult && linkCheckResult.data && linkCheckResult.data.link"
              :href="linkCheckResult.data.link"
              >Please click here to request a new password reset link.</a
            >
            <router-link v-else to="request-password-reset"
              >Please click here to request a new password reset link.
            </router-link>
          </p>
        </div>
        <p>
          If you are still having issues then please contact our customer services team at
          <a :href="`tel:${$t('site.supportPhoneNumber')}}`">{{ $t('site.supportPhoneNumber') }}</a> or by email at
          <a :href="`mailto:${$t('site.supportEmail')}}`">{{ $t('site.supportEmail') }}</a
          >.
        </p>
      </div>
    </div>
    <div v-else>
      <div class="row p-2">
        <div class="col-12">
          <p>
            Please enter a new password for your account. The password must have at least 8 characters, with at least
            one number, one lowercase, one uppercase, and one special character.
          </p>
        </div>
      </div>
      <div v-if="result && result.status === 204" class="row p-2">
        <div class="col-12 text-success">
          Your password has been changed successfully. You should be redirected shortly; if not click
          <router-link to="/auth/login">here.</router-link>
        </div>
      </div>
      <ValidationObserver v-else ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit()">
        <ValidationProvider
          ref="password"
          v-slot="{ errors }"
          name="password"
          rules="required|contains_a_symbol|contains_a_number|contains_lowercase|contains_uppercase|password_length"
        >
          <div class="row p-2">
            <div class="col-lg-3 col-xs-12">
              <label for="password">Password:</label>
            </div>
            <div class="col-lg-9 col-xs-12">
              <b-form-input id="password" v-model="password" name="password" type="password" />
            </div>
          </div>
          <div v-for="(error, index) in errors" v-bind:key="index" class="row p-2">
            <div class="col-12 text-danger">
              {{ error }}
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          ref="confirm_password"
          v-slot="{ errors }"
          name="confirm password"
          rules="required|password_match:@password"
        >
          <div class="row p-2">
            <div class="col-lg-3 col-xs-12">
              <label for="confirm_password">Confirm Password:</label>
            </div>
            <div class="col-lg-9 col-xs-12">
              <b-form-input id="confirm_password" v-model="confirm_password" name="confirm_password" type="password" />
            </div>
          </div>
          <div v-for="(error, index) in errors" v-bind:key="index" class="row p-2">
            <div class="col-12 text-danger">
              {{ error }}
            </div>
          </div>
        </ValidationProvider>
        <div v-if="result && result.status && result.status !== 204" class="row p-2" v-bind="result">
          <div v-if="Array.isArray(result.data.message)" class="col-12 text-danger">
            <div v-for="(message, i) in result.data.message" v-bind:key="i">{{ message }}</div>
          </div>
          <div v-else class="col-12 text-danger">
            {{ result.data.message }}
          </div>
          <div v-if="result && result.data && result.data.link" class="col-12 text-danger">
            Click <a :href="result.data.link">here</a> to request a new password reset link.
          </div>
        </div>
        <div class="row p-2">
          <div class="col-12">
            <button :disabled="invalid || submitting" class="btn bg-primary col-12" type="button" @click="submit">
              Save <i v-if="submitting" class="icon-spinner11 mr-2 spinner"></i>
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h2.title {
  @media only screen and (max-width: 561px) {
    font-size: 22px;
  }
}
</style>
